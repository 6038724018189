
import { ref, defineComponent, computed, Ref } from 'vue'
import { useStore } from 'vuex'
import DropdownUserAuthenticated from '@/components/layout/header/DropdownUserAuthenticated.vue'
import { RouteLink } from '@/ts/type'

export default defineComponent({
  name: 'TheNavbar',
  components: { DropdownUserAuthenticated },
  setup() {
    const store = useStore()
    const siteName = process.env.VUE_APP_SITE_NAME
    const isAuthenticated = computed(() => store.getters.isUserAuthenticated)
    
    const navbarLinks: Ref<RouteLink[]> = ref<RouteLink[]>([
      { routeName: 'Videos', text: 'VIDEOS' },
      { routeName: 'Yachts', text: 'YACHTS' },
      { routeName: 'Shipyards', text: 'SHIPYARDS' }
    ])

    const navbarLinksNotAuthenticated: Ref<RouteLink[]> = ref<RouteLink[]>([
      { routeName: 'Login', text: 'Login' }
    ])

    return {
      siteName,
      isAuthenticated,
      navbarLinks,
      navbarLinksNotAuthenticated
    }
  } 
})
