
import { defineComponent, ref } from 'vue'
import ShipyardService from '@/components/domain/shipyard/ShipyardService'
import { getYoutubeImgUrl } from '@/components/youtube'
import { Shipyard } from '@/ts/interface'

export default defineComponent({
  name: 'Shipyards',
    setup() {
      const shipyardService: ShipyardService = new ShipyardService()
      const shipyards = ref<Shipyard[]>([])

      shipyardService.getShipyards()        
        .then(data => shipyards.value = data)
        .catch(err => console.log(err))
      
            
      return {
        shipyards,
        getYoutubeImgUrl
      }
    }
})
