
import { RouteLink } from '@/ts/type'
import { ref, defineComponent, Ref } from 'vue'

export default defineComponent({
    name: 'TheFooter',
    setup() {
      const footerLinks: Ref<RouteLink[]> = ref<RouteLink[]>([
        { routeName: 'About', text: 'About' },
        { routeName: 'Terms', text: 'Terms of service' },
        { routeName: 'Privacy', text: 'Privacy policy' }
      ])

      return {
        footerLinks
      }
  } 
})
