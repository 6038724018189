
import { ref, defineComponent, computed, Ref } from 'vue'
import { useStore } from 'vuex'
import DropdownUserAuthenticated from '@/components/layout/header/DropdownUserAuthenticated.vue'
import { EntityStatus } from '@/ts/enum'
import { RouteLink } from '@/ts/type'

export default defineComponent({
  name: 'TheNavbar',
  components: { DropdownUserAuthenticated },
  setup() {
    const store = useStore()
    const siteName = process.env.VUE_APP_SITE_NAME
    const isAuthenticated = computed(() => store.getters.isUserAuthenticated)
    
    const navbarLinksAdmin: Ref<RouteLink[]> = ref<RouteLink[]>([
      { routeName: 'AdminHome', text: 'Admin Home' },
      { routeName: 'VideosAdmin', text: 'Videos Admin', params: { pageNumber: 1, statusFilter: EntityStatus.IMPORTED } },
      { routeName: 'ImportVideos', text: 'Import Videos' },
      { routeName: 'YachtsAdmin', text: 'Yachts Admin' },
      { routeName: 'ShipyardsAdmin', text: 'Shipyards Admin' }
    ])

    return {
      siteName,
      isAuthenticated,
      navbarLinksAdmin
    }
  } 
})
