
import { defineComponent, reactive } from 'vue'
import { getYoutubeEmbedUrl } from '@/components/youtube'
import VideoThumb from '@/components/domain/video/VideoThumb.vue'
import VideoService from '@/components/domain/video/VideoService'
import { VideoWithRelatedVideos } from '@/ts/interface'
import { useRouter } from 'vue-router'

export default defineComponent({
    name: 'VideoDetails',
    components: { VideoThumb },
    props: {
      id: Number
    },
    setup(props) {
      const router = useRouter()
      const videoService: VideoService = new VideoService()
      const videoDetails: VideoWithRelatedVideos = reactive({
        id: props.id!,
        title: '',
        yacht: null,
        youtubeId: '',
        importedAt: new Date(),
        activeUpdatedAt: new Date(),
        publishedUpdatedAt: new Date(),
        relatedVideosSameYacht: [],
        relatedVideosSameShipyard: []
      })
      

      videoService.getVideoDetailsById(props.id!)
        .then(videoWithRelatedVideosDTO => {
          setVideoDetails(videoWithRelatedVideosDTO)
        })
        .catch(err => {
          console.log(err.message)
          router.push('/error404')
      })

      
      function setVideoDetails(details: any): void {
        videoDetails.id = details.videoDTO.id
        videoDetails.title = details.videoDTO.title
        videoDetails.yacht = details.videoDTO.yacht
        videoDetails.youtubeId = details.videoDTO.youtubeId
        videoDetails.importedAt = details.videoDTO.importedAt
        videoDetails.activeUpdatedAt = details.videoDTO.activeUpdatedAt
        videoDetails.publishedUpdatedAt = details.videoDTO.publishedUpdatedAt
        videoDetails.relatedVideosSameYacht = details.relatedVideosSameYacht
        videoDetails.relatedVideosSameShipyard = details.relatedVideosSameShipyard
      }

      function truncateString(string: string, limit: number) {
        if (string.length > limit) {
          return string.substring(0, limit) + "..."
        } else {
          return string
        }
      }

      return {
        videoDetails,
        getYoutubeEmbedUrl,
        truncateString
      }
    }
})
