
import { computed, ComputedRef, defineComponent } from 'vue'

export default defineComponent({
  name: 'PaginationVideos',
  props: {
      pageNumber: {
        type: Number,
        default: 1
      },
      totalPages: {
        type: Number,
        default: 1
      }
  },
  setup(props) {
    const propsTotalPages: ComputedRef<number> = computed((): number => props.totalPages)
    
    function getPageNumber(addNumber: number = 0): number {
      return props.pageNumber + addNumber
    }

    return {
      getPageNumber,
      propsTotalPages
    }
  },
})
