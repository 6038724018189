
import { ref, reactive, defineComponent, ComputedRef, watch, computed, Ref } from 'vue'
import { getYoutubeImgUrl } from '@/components/youtube'
import YachtService from '@/components/domain/yacht/YachtService'
import ShipyardService from '@/components/domain/shipyard/ShipyardService'
import VideoImportService from '@/components/domain/video/VideoImportService'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import { ImportVideosByKeywordCommand } from '@/ts/type'
import { Shipyard, Yacht } from '@/ts/interface'

export default defineComponent({
  name: 'ImportVideos',
  components: { LoadingSpinner },
  setup() {
    const videoImportService: VideoImportService = new VideoImportService()
    const shipyardService: ShipyardService = new ShipyardService()
    const yachtService: YachtService = new YachtService()
    const shipyards: Ref<Shipyard[]> = ref<Shipyard[]>([])
    const selectedShipyard: Ref<number> = ref<number>(0)
    const yachts: Ref<Yacht[]> = ref<Yacht[]>([])
    const yachtSelectOptions: Ref<any> = ref<any[]>([])
    const apiFetchRunning: Ref<boolean> = ref<boolean>(false)
    
    const formData: ImportVideosByKeywordCommand = reactive({
      keyword: 'yachting',
      pageToken: 'first',
      maxResults: 50,
      yachtId: ref(0)
    })
    const importSummary = reactive({
      videos: [],
      videosFetchedFromApi: 0,
      videosAlreadyInDatabase: 0,
      videosImported: 0
    })
    const selectedYachtWatcher: ComputedRef<number> = computed((): number => formData.yachtId)
    const showImportResults: ComputedRef<boolean> = computed((): boolean => (importSummary.videosFetchedFromApi && !apiFetchRunning.value) === true)

    // when shipyard is selected: filter yachts by that shipyard
    watch([selectedShipyard], () => {
      yachtSelectOptions.value = filterYachtsByShipyard(yachts.value, selectedShipyard.value);
    })

    // when yacht is selected: set search keyword and reset page token to 1st page
    watch([selectedYachtWatcher], () => { 
      setSelectedYachtNameAsKeyword(selectedYachtWatcher.value) //formData.yachtId
      resetPageTokenToFirstPage()
    }) 
        

    shipyardService.getShipyards()        
      .then(data => shipyards.value = data)
      .catch(err => console.log(err))

    yachtService.getYachts()        
      .then(data => yachts.value = data)
      .catch(err => console.log(err))



    function submitForm() {
      importVideosByKeyword(formData)
      apiFetchRunning.value = true
    }

    function filterYachtsByShipyard(yachtList: any[], shipyardId: number): any[] {
      const filteredYachts: any = yachtList.filter(yacht => (yacht as any).shipyard.id === shipyardId)
      return filteredYachts
    }
    
    function importVideosByKeyword(command: ImportVideosByKeywordCommand): void {
      videoImportService.importVideosByKeyword(command)
        .then(res => res.json())
        .then(data => {
          formData.pageToken = data.nextPageToken
          importSummary.videos = data.videos
          importSummary.videosFetchedFromApi = data.videosFetchedFromApi
          importSummary.videosAlreadyInDatabase = data.videosAlreadyInDatabase
          importSummary.videosImported = data.videosImported
        })
        .then(() => apiFetchRunning.value = false)
        .catch(err => console.log(err.message))
    }

    function resetPageTokenToFirstPage() {
      formData.pageToken = 'first'
    }

    function setSelectedYachtNameAsKeyword(yachtId: number): void {
      const yachtsFilteredById: any[] = yachts.value.filter(yacht => (yacht as Yacht).id === yachtId)
      const yachtName: string = yachtsFilteredById[0].modelName
      const shipyardName: string = yachtsFilteredById[0].shipyard.name

      formData.keyword = shipyardName + ' ' + yachtName
    }

    return {
      formData,
      submitForm,
      importSummary,
      importVideosByKeyword,
      resetPageTokenToFirstPage,
      getYoutubeImgUrl,
      shipyards, selectedShipyard,
      yachts, yachtSelectOptions,
      apiFetchRunning,
      showImportResults
    }
  }
})
