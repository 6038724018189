
import { ref, defineComponent, computed, Ref } from 'vue'
import ShipyardService from '@/components/domain/shipyard/ShipyardService'
import { Shipyard } from '@/ts/interface'

export default defineComponent({
  name: 'YachtFormInputFields',
  props: {
      formData: {
        type: Object
      }
  },
  setup(props) {
    const shipyardService: ShipyardService = new ShipyardService()
    const shipyards: Ref<Shipyard[]> = ref<Shipyard[]>([])
    
    shipyardService.getShipyards()        
      .then(data => shipyards.value = data)
      .catch(err => console.log(err.message))

    const formData = computed(() => props.formData)

    return {
      shipyards,
      formData
    }
  }
})
