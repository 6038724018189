export default class LoginCommand {
  username: string
  email: string
  password: string
  

  constructor() {
    this.username = ''
    this.email = ''
    this.password = ''
  }
}