
import { ref, defineComponent } from 'vue'
import { Yacht } from '@/ts/interface'
import YachtService from '@/components/domain/yacht/YachtService'
import ToastService from '@/components/common/ToastService'
import { getYoutubeImgUrl } from '@/components/youtube'


export default defineComponent({
  setup() {
    const yachtService: YachtService = new YachtService()
    const toastService: ToastService = new ToastService()
    const yachts = ref<Yacht[]>([])
    
    yachtService.getYachts()        
      .then(data => yachts.value = data)
      .catch(err => console.log(err.message))


    function deleteYacht(yachtId: number) {
      yachtService.deleteYacht(yachtId)        
        .then(() => {
            yachts.value = yachts.value.filter(yacht => (yacht as Yacht).id !== yachtId)
            toastService.raiseToast(`Yacht deleted.`, 'info')
          })
        .catch(err => toastService.raiseToast('Error: Not deleted.', 'danger'))
    }

    return {
      yachts,
      deleteYacht,
      getYoutubeImgUrl
    }
  }
})
