
import { defineComponent, reactive, ref } from 'vue'
import RegisterCommand from '@/components/auth/RegisterCommand'
import UserService from '@/components/auth/user/UserService'

export default defineComponent({
  name: 'Register',
  setup() {
    const userService: UserService = new UserService()
    const formData: RegisterCommand = reactive(new RegisterCommand())
    const userAlert = reactive({
      show: false,
      message: '',
      type: ''
    })

    function submitForm() {
      registerUser(formData)
    }

    function registerUser(formData: RegisterCommand) {
      userService.registerUser(formData)
        .then(res => res.json())
        .then(data => {
          if (data.message) {
            throw new Error('Registration failed: ' + data.message)
          }
          else {
            showUserAlertRegistrationSuccessful()
          }
        })
        .catch(err => {
          showUserAlertRegistrationFailed(err.message)
        })
    }

    function showUserAlertRegistrationSuccessful() {
      userAlert.show = true
      userAlert.message = 'Registration successful!'
      userAlert.type = 'success'
    }

    function showUserAlertRegistrationFailed(message: string) {
      userAlert.show = true
      userAlert.message = message
      userAlert.type = 'danger'
    }

    return {
      formData,
      submitForm,
      userAlert
    }
  }
})
