
import { ref, defineComponent } from 'vue'
import { Shipyard } from '@/ts/interface'
import ShipyardService from '@/components/domain/shipyard/ShipyardService'
import ToastService from '@/components/common/ToastService'

export default defineComponent({
  setup() {
    const shipyardService: ShipyardService = new ShipyardService()
    const toastService: ToastService = new ToastService()
    const shipyards = ref<Shipyard[]>([])
    
    shipyardService.getShipyards()        
      .then((data: Shipyard[]) => shipyards.value = data)
      .catch(err => console.log(err.message))


    function deleteShipyard(shipyardId: number): void {
      shipyardService.deleteShipyard(shipyardId)        
        .then(() => {
            shipyards.value = shipyards.value.filter(shipyard => (shipyard as Shipyard).id !== shipyardId)
            toastService.raiseToast(`Shipyard deleted.`, 'info')
          })
        .catch(err => toastService.raiseToast('Error: Not deleted.', 'danger'))
    }

    return {
      shipyards,
      deleteShipyard
    }
  }
})
