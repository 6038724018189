
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'DropdownUserAuthenticated',
  setup() {
    const store = useStore()
    const username = computed(() => store.getters.getUser?.username)

    return {
      username
    }
  },
})
