<template>

  <div class="loadingio-spinner-spinner-13tzyrc2ivmf">
    <div class="ldio-layf9s2axbn">
      <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
    </div>
  </div>

</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LoadingSpinner',
  setup() {
    
  },
})
</script>

<style scoped>


/* 
  Loader
  generated by https://loading.io/
  License: https://loading.io/license/#free-license 
*/
@keyframes ldio-layf9s2axbn {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.ldio-layf9s2axbn div {
  left: 94px;
  top: 48px;
  position: absolute;
  animation: ldio-layf9s2axbn linear 1.25s infinite;
  background: #021631;
  width: 12px;
  height: 24px;
  border-radius: 6px / 12px;
  transform-origin: 6px 52px;
}.ldio-layf9s2axbn div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1458333333333333s;
  background: #021631;
}.ldio-layf9s2axbn div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1.0416666666666665s;
  background: #021631;
}.ldio-layf9s2axbn div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9374999999999999s;
  background: #021631;
}.ldio-layf9s2axbn div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8333333333333333s;
  background: #021631;
}.ldio-layf9s2axbn div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7291666666666665s;
  background: #021631;
}.ldio-layf9s2axbn div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6249999999999999s;
  background: #021631;
}.ldio-layf9s2axbn div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5208333333333333s;
  background: #021631;
}.ldio-layf9s2axbn div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.41666666666666663s;
  background: #021631;
}.ldio-layf9s2axbn div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.31249999999999994s;
  background: #021631;
}.ldio-layf9s2axbn div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.20833333333333331s;
  background: #021631;
}.ldio-layf9s2axbn div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.10416666666666666s;
  background: #021631;
}.ldio-layf9s2axbn div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #021631;
}
.loadingio-spinner-spinner-13tzyrc2ivmf {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-layf9s2axbn {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-layf9s2axbn div { box-sizing: content-box; }

</style>
