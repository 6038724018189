
import { defineComponent, reactive, Ref, ref, watch } from 'vue'
import VideoService from '@/components/domain/video/VideoService'
import ToastService from '@/components/common/ToastService'
import { EntityStatus } from '@/ts/enum'
import { getYoutubeEmbedUrl } from '@/components/youtube'
import { VideoCommand } from '@/ts/type'
import YachtService from '@/components/domain/yacht/YachtService'
import ShipyardService from '@/components/domain/shipyard/ShipyardService'
import { Shipyard, Yacht } from '@/ts/interface'

export default defineComponent({
  name: 'EditVideo',
  props: {
    id: {
      type: Number
    }
  },
  setup(props) {
    const videoService: VideoService = new VideoService()
    const toastService: ToastService = new ToastService()
    const shipyardService: ShipyardService = new ShipyardService()
    const shipyards: Ref<Shipyard[]> = ref<Shipyard[]>([])
    const yachtService: YachtService = new YachtService()
    const selectedShipyard: Ref<number> = ref<number>(0)
    const selectedYacht: Ref<number> = ref<number>(0)
    const yachts: Ref<Yacht[]> = ref<Yacht[]>([])
    const yachtSelectOptions: Ref<any> = ref<any[]>([])
    const formData: VideoCommand = reactive({
      title: '',
      youtubeId: '',
      yachtId: ref(0),
      shipyardId: ref(0)
    })

    // when selected shipyard is changed: filter yachts by that shipyard
    watch([selectedShipyard], () => {
      formData.shipyardId = selectedShipyard.value;
      yachtSelectOptions.value = filterYachtsByShipyard(yachts.value, selectedShipyard.value);
      selectedYacht.value = formData.yachtId!
    })

    // when selected yacht is changed
    watch([selectedYacht], () => {
      formData.yachtId = selectedYacht.value;
    })


    shipyardService.getShipyards()
      .then(data => {shipyards.value = data})
      .catch(err => console.log(err.message))

    yachtService.getYachts()
      .then(data => { 
        yachts.value = data
        yachtSelectOptions.value = yachts.value
      })
      .catch(err => console.log(err))
    
    getVideoAndSetFormData(props.id!)
    

    function submitForm(): void {
      editVideo(props.id!, formData)
    }

    function editVideo(videoId: number, videoCommand: VideoCommand): void {
      videoService.editVideo(videoId, videoCommand)
        .then(data => {
            toastService.raiseToast(
              `Video ${data.id} saved.`, 
              'success')
        })
        .catch(err => {
            toastService.raiseToast(
              'ERROR - Changes not saved: ' + err.message, 
              'danger')
        })
    }

    function getVideoAndSetFormData(videoId: number): void {
      videoService.getVideoById(videoId)
        .then(data => {
          formData.title = data.title
          formData.youtubeId = data.youtubeId
          formData.yachtId = data.yacht?.id!
          formData.shipyardId = data.yacht?.shipyard.id!

          selectedShipyard.value = formData.shipyardId
          yachtSelectOptions.value = filterYachtsByShipyard(yachts.value, selectedShipyard.value);
        })
        .catch(err => {
          toastService.raiseToast(
            'ERROR - Data fetch failed: ' + err.message, 
            'danger')
        })
    }

    function filterYachtsByShipyard(yachtList: any[], shipyardId: number): any[] {
      const filteredYachts: any = yachtList.filter(yacht => (yacht as any).shipyard.id === shipyardId)
      return filteredYachts
    }

    function removeSelectedYacht() {
      selectedShipyard.value = 0
      selectedYacht.value = 0
      formData.shipyardId = null
      formData.yachtId = null
    }

    return {
      formData,
      submitForm,
      EntityStatus,
      getYoutubeEmbedUrl,
      shipyards, selectedShipyard,
      yachts, yachtSelectOptions, selectedYacht,
      removeSelectedYacht
    }
    

  }
})
