
import { reactive, defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import YachtFormInputFields from '@/components/domain/yacht/YachtFormInputFields.vue'
import YachtService from '@/components/domain/yacht/YachtService'
import ToastService from '@/components/common/ToastService'
import YachtCommand from '@/components/domain/yacht/YachtCommand'

export default defineComponent({
  name: 'AddYacht',
  components: { YachtFormInputFields },
  setup() {
    const router = useRouter()
    const yachtService: YachtService = new YachtService()
    const toastService: ToastService = new ToastService()
    const formData: YachtCommand = reactive(new YachtCommand())


    function submitForm() {
      addYacht(formData)
    }

    function addYacht(yachtCommand: YachtCommand): void {
      yachtService.addYacht(yachtCommand)        
        .then(data => {
          toastService.raiseToast(
            `Yacht ${data.modelName} added.`, 
            'success')
          router.push('/admin/yachts')
        })
        .catch(err => {
            toastService.raiseToast(
              'ERROR -  not added: ' + err.message, 
              'danger')
        })
    }

    return {
      formData,
      submitForm
    }
  }
})
