
import ShipyardService from '@/components/domain/shipyard/ShipyardService'
import VideoService from '@/components/domain/video/VideoService'
import { Shipyard, Video, Yacht } from '@/ts/interface'
import VideoThumb from '@/components/domain/video/VideoThumb.vue'
import { defineComponent, Ref, ref } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'ShipyardDetails',
  components: { VideoThumb },
  props: {
    id: {
      type: Number
    }
  },
  setup(props) {
    const router = useRouter()
    const shipyardService: ShipyardService = new ShipyardService()
    const videoService: VideoService = new VideoService()
    const shipyard: Ref<Shipyard> = ref({} as Shipyard)
    const videosByShipyard: Ref<Video[]> = ref<Video[]>([])

    shipyardService.getShipyardById(props.id!)
      .then((data: Shipyard) => shipyard.value = data)
      .catch(err => {
        console.log(err.message)
        router.push('/error404')
      })

    videoService.getVideosByShipyard(props.id!)
      .then((data: Video[]) => videosByShipyard.value = data)
      .catch(err => console.log(err.message))


    return {
      shipyard,
      videosByShipyard
    }
  },
})
