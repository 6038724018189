
import { defineComponent, Ref, ref } from 'vue'
import YachtService from '@/components/domain/yacht/YachtService'
import { getYoutubeImgUrl } from '@/components/youtube'
import { Yacht } from '@/ts/interface'

export default defineComponent({
  name: 'Yachts',
  setup() {
    const yachtService: YachtService = new YachtService()
    const yachts: Ref<Yacht[]> = ref<Yacht[]>([])
    

    yachtService.getYachts()        
      .then(data => yachts.value = data)
      .catch(err => console.log(err))

    function getYachtImgUrl(youtubeThumbId: string): string {
      const placeholderYachtImgThumbId: string = 'C5dMg1Jc9yA'
      const thumbId: string =  youtubeThumbId?.length ? youtubeThumbId : placeholderYachtImgThumbId
      return getYoutubeImgUrl(thumbId)
    }

    return {
      yachts,
      getYachtImgUrl
    }
  }
})
