
import { defineComponent, reactive } from 'vue'
import ShipyardFormInputFields from '@/components/domain/shipyard/ShipyardFormInputFields.vue'
import ShipyardService from '@/components/domain/shipyard/ShipyardService'
import ToastService from '@/components/common/ToastService'
import ShipyardCommand from '@/components/domain/shipyard/ShipyardCommand'

export default defineComponent({
  name: 'EditShipyard',
  components: { ShipyardFormInputFields },
  props: {
      id: {
        type: Number
      }
  },
  setup(props) {
    const shipyardService: ShipyardService = new ShipyardService()
    const toastService: ToastService = new ToastService()
    const formData: ShipyardCommand = reactive(new ShipyardCommand())

    getShipyardByIdAndSetFormData(props.id!)
    

    function submitForm(): void {
      editShipyard(props.id!, formData)
    }

    function editShipyard(shipyardId: number, shipyardCommand: ShipyardCommand): void {
      shipyardService.editShipyard(shipyardId, shipyardCommand)        
        .then(data => {
            toastService.raiseToast(
              `Shipyard ${data.name} saved.`, 
              'success')
        })
        .catch(err => {
            toastService.raiseToast(
              'ERROR - Changes not saved: ' + err.message, 
              'danger')
        })
    }

    function getShipyardByIdAndSetFormData(shipyardId: number): void {
      shipyardService.getShipyardById(shipyardId)        
        .then(data => { 
          formData.name = data.name 
          formData.country = data.country 
          formData.yearFounded = data.yearFounded 
          formData.websiteUrl = data.websiteUrl 
          formData.youtubeChannelUrl = data.youtubeChannelUrl 
        })
        .catch(err => {
          toastService.raiseToast('ERROR - Data fetch failed: ' + err.message, 'danger')
        })
    }

    return {
      formData,
      submitForm
    } 


  }
})
