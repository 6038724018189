
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'ShipyardFormInputFields',
  props: {
      formData: {
        type: Object
      }
  },
  setup(props) {
    const formData = computed(() => props.formData)

    return {
      formData
    }
  }
})
