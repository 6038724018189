
import { computed, ComputedRef, defineComponent } from 'vue'
import { getYoutubeImgUrl } from '@/components/youtube'

export default defineComponent({
  name: 'VideoThumb',
  props: {
    id: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    youtubeId: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const id: ComputedRef<number> = computed((): number => props.id) 
    const title: ComputedRef<string> = computed((): string => props.title)
    const youtubeId: ComputedRef<string> = computed((): string => props.youtubeId)
    
    return {
      id,
      title,
      youtubeId,
      getYoutubeImgUrl
    }
  }
})
