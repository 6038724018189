
import { EntityStatus } from '@/ts/enum'
import { computed, ComputedRef, defineComponent } from 'vue'

export default defineComponent({
  name: 'PaginationVideosAdmin',
  props: {
      pageNumber: {
        type: Number,
        default: 1
      },
      statusFilter: {
        type: String,
        default: EntityStatus.IMPORTED
      },
      totalPages: {
        type: Number,
        default: 1
      }
  },
  setup(props) {
    const propsTotalPages: ComputedRef<number> = computed((): number => props.totalPages)
    const propsStatusFilter: ComputedRef<string> = computed((): string => props.statusFilter)
    
    function getPageNumber(addNumber: number = 0): number {
      return props.pageNumber + addNumber
    }

    return {
      getPageNumber,
      propsTotalPages,
      propsStatusFilter
    }
  },
})
