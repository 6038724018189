
import dayjs from 'dayjs';
import { computed, ComputedRef, defineComponent, Ref, ref } from 'vue'
import VideoService from '@/components/domain/video/VideoService'
import { EntityStatus, ActivationAction, PublishingAction } from '@/ts/enum'
import PaginationVideosAdmin from '@/components/domain/video/PaginationVideosAdmin.vue'
import { getYoutubeImgUrl } from '@/components/youtube'
import { EntityPage, Video } from '@/ts/interface'

export default defineComponent({
  name: 'VideosAdmin',
  components: { PaginationVideosAdmin },
  props: {
      statusFilter: {
        type: String,
        default: EntityStatus.IMPORTED
      },
      pageNumber: {
        type: Number,
        default: 1
      }
  },
  setup(props) {
    const videoService: VideoService = new VideoService()
    const videos: Ref<Video[]> = ref<Video[]>([])
    const videosFound: Ref<boolean> = ref<boolean>(true)
    const totalPages: Ref<number> = ref<number>(0)
    const pageSize: Ref<number> = ref<number>(0)
    const pageIndex: Ref<number> = ref<number>(0)
    const propsStatusFilter: ComputedRef<string> = computed((): string => props.statusFilter)
    const propsPageNumber: ComputedRef<number> = computed((): number => props.pageNumber)
    const activeRowIndex: Ref<number> = ref<number>(-1)
    
    const rowHighlightColor = {
      green: false,
      yellow: false,
      red: false,
      lightblue: false,
      reset(): void {
        this.green = false
        this.yellow = false
        this.red = false
        this.lightblue = false
      }
    }

    getVideosPage(props.statusFilter, props.pageNumber)


    function getVideosPage(status: string, pageNum: number): void {
      videoService.getAdminVideosPage(status, pageNum)
        .then((data: EntityPage) => {
          videos.value = data.content as Video[];
          totalPages.value = data.totalPages
          pageSize.value = data.size
          pageIndex.value = data.number
        })
        .catch(err => {
            console.log(err.message)
            videosFound.value = false
        })
    }

    function updateActivationStatus(activationAction: ActivationAction, id: number, rowIndex: number): void {
      highlightRow(rowIndex, activationAction)
      videoService.changeActivationStatus(id, activationAction)
        .then(() => videos.value = videos.value.filter(video => (video as Video).id !== id))
        .then(() => clearRowHighlights())
        .catch(err => console.log(err.message))
    }

    function updatePublishingStatus(publishingAction: PublishingAction, id: number, rowIndex: number): void {
      highlightRow(rowIndex, publishingAction)
      videoService.changePublishingStatus(id, publishingAction)
        .then(() => videos.value = videos.value.filter(video => (video as Video).id !== id))
        .then(() => clearRowHighlights())
        .catch(err => console.log(err.message))
    }

    function highlightRow(rowIndex: number, action: ActivationAction | PublishingAction): void {
      switch (action) {
        case PublishingAction.PUBLISH:
          rowHighlightColor.green = true 
          break;
        case PublishingAction.UNPUBLISH:
          rowHighlightColor.yellow = true 
          break;
        case ActivationAction.ACTIVATE:
          rowHighlightColor.lightblue = true
          break;
        case ActivationAction.DEACTIVATE:
          rowHighlightColor.red = true
          break;
        default:
          break;
      }
      activeRowIndex.value = rowIndex;
    }

    function clearRowHighlights(): void {
      activeRowIndex.value = -1;
      rowHighlightColor.reset()
    }

    function formatDate(dateString: string) {
      const date = dayjs(dateString);
      return date.format('DD.MM.YYYY HH:mm');
    }

    return {
      videos, videosFound,
      ActivationAction, PublishingAction,
      updateActivationStatus, updatePublishingStatus,
      getYoutubeImgUrl, formatDate, 
      propsPageNumber, totalPages, pageSize, pageIndex, 
      EntityStatus, propsStatusFilter,
      activeRowIndex, highlightRow, rowHighlightColor
    }
  },
})
