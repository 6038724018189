
import YachtService from '@/components/domain/yacht/YachtService'
import VideoService from '@/components/domain/video/VideoService'
import { Video, Yacht } from '@/ts/interface'
import VideoThumb from '@/components/domain/video/VideoThumb.vue'
import { defineComponent, Ref, ref } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'YachtDetails',
  components: { VideoThumb },
  props: {
    id: {
      type: Number
    }
  },
  setup(props) {
    const router = useRouter()
    const yachtService: YachtService = new YachtService()
    const videoService: VideoService = new VideoService()
    const yacht: Ref<Yacht> = ref({} as Yacht)
    const videosByYacht: Ref<Video[]> = ref<Video[]>([])

    yachtService.getYachtById(props.id!)
      .then((data: Yacht) => yacht.value = data)
      .catch(err => {
        console.log(err.message)
        router.push('/error404')
      })

    videoService.getVideosByYacht(props.id!)
      .then((data: Video[]) => videosByYacht.value = data)
      .catch(err => console.log(err.message))


    return {
      yacht,
      videosByYacht
    }
  },
})
